.error {
  width: 100%;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--koala-text);
  background-color: var(--koala-background);
  font-weight: 200;
  text-align: center;
  align-self: center;

  h1 {
    font-size: 2.25rem;
    font-weight: 400;
    margin: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 200;
    margin: 1rem;
  }

  &__home {
    margin: 1.5rem;
  }
}
