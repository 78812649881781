body {
  color: var(--koala-text);
}

.support {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page_title {
  font-size: 2.4rem;
  margin-bottom: 1.4rem;
}

.support_container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 3rem 2rem;
}

.contact_icon {
  color: var(--koala-light-text);
  font-size: 5rem;
  justify-self: center;
  align-self: center;
}

#email_icon {
  filter: drop-shadow(0px 3px 4px var(--koala-text));
}

#discord_icon {
  filter: drop-shadow(0px 3px 4px #7289da);
}

#twitter_icon {
  filter: drop-shadow(0px 3px 4px #00acee);
}

.text_section {
  padding: 2rem;
  align-self: center;
  border-bottom: 1px solid var(--koala-border);
}

#discord_section {
  box-shadow: 0 5px 14px #7289da, 0px 2px 4px #7289da;
  border: none;
  border-radius: 20px;
}

#address {
  font-style: italic;
}

#discord_link {
  color: var(--koala-lightgray);
  font-weight: 600;
}
