:root {
  --table-header: #b3b3b3;
  --table-background: #cccccc;
  --table-cell: #b3b3b3;
  --table-field: #b3b3b3;
  --modal-field: #949494;
  --modal-input-text: white;
  --modal-input-text-disabled: #797979;
  --table-border: #808080;
  --table-text: white;
  --table-button: #00a775;
  --table-pagination: #4f4fcc;
}

[data-theme='dark'] {
  --table-header: #292f33;
  --table-background: #292f33;
  --table-cell: #292f33;
  --table-field: #1f2429;
  --modal-field: #1f2429;
  --modal-input-text: white;
  --modal-input-text-disabled: #808080;
  --table-border: transparent;
  --table-text: white;
  --table-button: #00a775;
  --table-pagination: white;
}

.cogSection {
  background-color: var(--table-header);
  display: flex;
  flex-direction: column;
  border: 2px solid var(--table-border);
}

.cogSectionBody {
  background-color: var(--table-background);
  border-top: 2px solid var(--table-border);
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.cogSectionTitle {
  font-size: 2rem;
  margin: 5px;
  color: var(--table-text);
  font-weight: 400;
  margin-left: 20px;
}

.cogNoData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.25rem;
  padding: 1rem;
  text-align: center;
  color: var(--table-text);
  font-weight: 400;
  width: 100%;
}

.cogModalDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: var(--koala-text);
  font-weight: 400;
  height: 100%;
}

hr {
  border: 1px solid #4d4d4d;
  margin-top: 20px;
  width: 100%;
}

.cogSectionSubtitle {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.cogSectionEnable {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: var(--koala-text);
  font-weight: 400;
  height: 100%;
}
