.settings {
  &-grid {
    margin: auto;
    min-height: 0vh;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 3rem;
    column-gap: 1rem;
    text-align: center;
  }
}
