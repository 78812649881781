#topSection {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-start;
}

#topSection.between {
  justify-content: space-between;
}

#topSection.right {
  justify-content: flex-end;
}

.tableHeaderOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sensitiveDataFilter {
  padding: 1rem;
}

.bottomSection {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.bottomSection--right {
  justify-content: flex-end;
}

.bottomSection__unselected {
  color: var(--table-pagination);
  font-weight: 400;
  opacity: 0.9;
  cursor: pointer;
  padding: 8px;
  font-size: 1.1rem;
}

.bottomSection__selected {
  color: var(--table-pagination);
  font-weight: 600;
  padding: 6px;
  font-size: 1.1rem;
}

.tableInput {
  background-color: var(--table-field);
  color: #e5e5e5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  outline: none;
  font-size: 1.3rem;
  font-weight: 200;
}

.tableInput::placeholder {
  color: #e5e5e5;
  font-size: 1.3rem;
  font-weight: 200;
}

table {
  width: 100%;
  margin-top: 10px;
  background-color: var(--table-cell);
  color: white;
  border-collapse: collapse;
  font-weight: 300;
  font-size: 1.3rem;
}

table td {
  border: 2px solid #808080;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

table th {
  border: 2px solid #808080;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.toggleSort {
  padding-left: 10px;
  cursor: pointer;
}

.tableHeaderText {
  font-size: 1.4rem;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  justify-self: flex-end;
}

.tableHeaderOption {
  margin-right: 10px;
  padding: 5px;
  font-size: 1.2rem;
  border: none;
  background-color: var(--table-field);
  color: white;
  cursor: pointer;
  outline: none;
}

.tableOptions {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tableOptions > * {
  margin: 0 1rem 0 1rem;
}

input[type='checkbox'] {
  margin: auto;
  cursor: pointer;
}
