@font-face {
  font-family: SSP-reg;
  src: url(../assets/fonts/SourceSansPro-Regular.ttf);
}

.landing *,
::after,
::before {
  box-sizing: border-box;
}

.landing html {
  font-family: 'SSP-reg';
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.landing body {
  overflow: auto;
}

a {
  text-decoration: none;
}

.landing {
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  color: white;
  font-family: 'SSP-reg';

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.1rem;

    &__left {
      flex-direction: row;
      display: flex;
      padding-left: 1rem;
      @media (min-width: 1000px) {
        padding-left: 0.25rem;
      }
    }

    &__right {
      flex-direction: row;
      display: flex;
      padding-right: 1rem;
      @media (min-width: 1000px) {
        padding-right: 0.25rem;
      }
    }

    &__logo {
      margin-top: 0.5rem;
      @media (min-width: 1000px) {
        display: none;
      }

      @media (max-width: 1000px) {
        max-width: 100%;
        max-height: 3rem;
        object-fit: cover;
      }
    }
  }

  &__body {
    color: #313437;
    background-color: #b3b3b3;
  }

  &__header {
    background: linear-gradient(0deg, #038c7f, #00aa6e);

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3rem;
      margin-left: -1rem;
      margin-right: -1rem;

      @media (max-width: 1000px) {
        margin-top: unset;
      }

      a {
        color: white;
      }
    }

    &__title {
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      font-size: 6rem;
      padding-top: 5rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
    }

    &__logo {
      max-height: 100%;
      max-width: 100%;
    }

    &__divider {
      height: 'auto';
      margin-bottom: -9vw;
    }

    @media (max-width: 1000px) {
      &__logo {
        display: none;
      }

      &__title {
        text-align: center;
      }
    }
  }

  &__brands {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;

    &__logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: -5rem;
      margin-left: 2rem;

      @media (max-width: 720px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }

    &-logo {
      max-height: 6rem;
      margin: 2rem;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: center;
    background-color: #f7f7f7;

    &__list {
      display: flex;
      justify-content: center;
      flex-direction: row;

      a {
        color: #313437;
        &:hover {
          opacity: 0.75;
        }
      }

      &-item {
        margin: 0.5rem;
        cursor: pointer;
      }
    }

    &__copyright {
      align-self: center;
      opacity: 0.5;
      font-size: 0.8rem;
      padding: 1rem;
    }

    &__socials {
      display: flex;
      flex-direction: row;
      width: 10rem;
      align-self: center;
      justify-content: space-evenly;
      font-size: 1.5rem;
      margin: 1rem;
      color: #313437;

      &-social {
        font-size: 2rem;
        display: inline-block;
        text-align: center;
        color: inherit;
        padding: 1rem;
        opacity: 0.75;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__btn {
    cursor: pointer;
    padding: 0.75rem;
    margin: 0.5rem;
    width: fit-content;

    &-border {
      border-radius: 2rem 2rem 2rem 2rem;
      border-style: solid;
      border-color: white;
      border-width: 1px;
    }

    &:hover {
      opacity: 0.9;
    }

    @media (max-width: 1000px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__article {
    &__empty {
      color: transparent;
      user-select: none;
    }
  }

  &__features {
    color: #313437;
    background-color: #666666;
    padding-bottom: 2rem;

    &__title h2 {
      font-size: 2.5rem;
      margin: 0 auto 4rem;
      font-weight: 700;
      color: white;
      text-align: center;
    }

    &__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__item {
      min-height: 100px;
      padding-left: 80px;
      margin-bottom: 40px;

      &-name {
        font-size: 20px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 20px;
        color: white;
      }

      &-description {
        font-size: 15px;
        margin-bottom: 0;
        color: white;
      }

      &-icon {
        font-size: 40px;
        color: #7289da;
        float: left;
        margin-left: -65px;
      }
    }
  }

  &__partners {
    color: white;
    padding-top: 4rem;
    margin-top: 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;

    @media (max-width: 1000px) {
      padding-top: 1rem;
      margin-bottom: 3rem;
    }
  }

  &__interview {
    background-color: #666666;
    width: 100%;
    padding-bottom: 4rem;

    &__icon {
      font-size: 40px;
      color: #7289da;
      margin-bottom: -0.25rem;
    }

    &__title {
      display: inline-block;
      color: #fff;
      font-weight: 600;
      margin: 1rem;
      font-size: 2rem;
    }

    &__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__video-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
    }
  }

  &__people {
    font-size: 16px;
    margin: 0 auto;

    &__header {
      margin-bottom: 40px;
      padding-top: 40px;
      font-size: 2.5rem;
      text-align: center;
      color: rgb(49, 52, 55);
      font-weight: 700;
    }

    &__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      padding: 1.25rem 1.25rem;
      width: inherit;
      padding-bottom: 0;
    }

    &__item {
      text-align: center;
      padding: 1.875rem;
      background-color: #fff;
      margin-bottom: 30px;
    }

    &__name {
      font-size: 1.25rem;
      font-weight: 700;
      margin-top: 28px;
      margin-bottom: 8px;
      color: inherit;
    }

    &__title {
      font-size: 1.25rem;
      text-transform: uppercase;
      font-weight: 700;
      color: #d0d0d0;
      letter-spacing: 2px;
      font-size: 13px;
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &__social {
      margin: 1rem;
      color: rgb(162, 168, 174);

      &:hover {
        opacity: 0.75;
      }
    }

    &__pic {
      object-fit: cover;
      overflow: hidden;
      background-repeat: no-repeat;
      width: 160px;
      height: 160px;
      background-position: center;
      border-radius: 50%;
    }
  }
}

.disabled,
.disabled a {
  cursor: default;
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
  }
}

.invisible {
  visibility: hidden;
}

.pagination {
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 10px;

  &__button {
    border: none;
    width: 3.125rem;
    border-radius: 1.5625rem;
    color: black;
    background-color: white;
    aspect-ratio: 1/1;
  }
}

.discordBtn {
  background-color: #7289da;
}
