input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type='number'] {
  -moz-appearance: textfield;
}

.darkBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modalClose {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.modal {
  z-index: 100;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 30%;
  max-width: 50%;
  max-height: 75%;
  width: fit-content;
  height: fit-content;
  background-color: var(--table-header);
  ::placeholder {
    color: var(--modal-input-text);
  }

  &__header {
    height: 4rem;
    border-bottom: solid 2px var(--koala-border);
    width: 100%;
    color: var(--table-text);
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    background-color: var(--table-header);

    &-text {
      font-size: 1.3rem;
      font-weight: 500;
      padding: 1rem;
      align-self: center;
    }
  }

  &__content {
    height: fit-content;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
  }

  &__label {
    color: var(--table-text);
    margin: 0.5rem 0 0 0;
    align-self: flex-start;
    font-weight: 500;
    font-size: 1.25rem;
    &-centered {
      @extend .modal__label;
      align-self: center;
    }
  }

  &__input {
    margin: 0.25rem;
    width: 100%;
    min-height: 1.75rem;
    background-color: var(--modal-field);
    border: none;
    color: var(--modal-input-text);
    -webkit-appearance: menulist-button;
    &-disabled {
      @extend .modal__input;
      color: var(--modal-input-text-disabled);
    }
  }

  &__extendedInput {
    @extend .modal__input;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  &__footer > * {
    margin: 1rem 0 1rem 0;
  }

  &__footer {
    border-top: solid 2px var(--koala-border);
    padding: 1rem 0 1rem 0;
    width: 100%;
    display: flex;
    background-color: var(--table-header);
  }

  &__footer > * {
    margin: 0 3rem 0 3rem;
  }
}
