.documentation {
  color: var(--koala-text);
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 70vw;
  margin-bottom: 2rem;

  &__text {
    color: var(--koala-text);
  }

  &__title {
    text-align: center;
  }

  &__commandCol {
    width: 20%;
  }
  &__exampleCol {
    width: 10%;
  }
  &__paramCol {
    width: 20%;
  }
  &__descCol {
    width: 50%;
  }

  &__expandable {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
  }

  &__hidden {
    display: none;
  }

  &__expandable &__divider {
    height: 1px;
    width: 100%;
    align-self: center;
    margin-left: 1rem;
    background-color: var(--koala-text);
    margin-top: 0.5rem;
  }

  &__expandable &__caret {
    align-self: center;
    margin-right: 1rem;
  }

  &__expandable &__cogname {
    white-space: nowrap;
  }
  &__optional {
    color: var(--koala-lightgray);
  }

  &__btn {
    cursor: pointer;
    padding: 0.75rem;
    margin: auto;
    width: fit-content;
    align-self: center;
    background-color: var(--koala-green);

    &-border {
      border-radius: 2rem 2rem 2rem 2rem;
      border-style: solid;
      border-color: var(--koala-text);
      border-width: 1px;
    }

    &:hover {
      opacity: 0.9;
    }
    &__table {
      table-layout: fixed;
      width: 100%;
      word-break: break-all;
    }

    @media (max-width: 1000px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.docshome {
  color: var(--koala-text);
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 70vw;
  margin-bottom: 0.5rem;

  &__title {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: xx-large;
  }

  &__container {
    text-align: center;
    width: 60vw;
    align-self: center;
  }

  &__grid {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    row-gap: 0.1rem;
    column-gap: 0.1rem;
    text-align: left;
  }
}
