:root {
  --koala-icon: #f7f7f7;
  --koala-about-border: lightgray;
}

[data-theme='dark'] {
  --koala-icon: none;
  --koala-about-border: none;
}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.about_header {
  color: var(--koala-text);
  text-align: center;
}

.about_header > h1 {
  font-size: 3rem;
}

.about_section {
  width: 100%;
  color: var(--koala-text);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem 4rem;
  margin-bottom: 2rem;
}

.about_article {
  font-size: 1.1rem;
  padding: 2rem;
  background-color: var(--koala-section-background);
  border: 1px solid var(--koala-about-border);
  border-radius: 12px;
  min-width: 340px;
  width: 45vw;
}

.about_article:nth-child(even) {
  background-color: var(--koala-section-background-dark);
}

.about_article_icon {
  font-size: 30pt;
  padding: 2px;
  background-color: var(--koala-icon);
  color: var(--koala-green);
  float: left;
  margin-top: -16px;
  margin-left: -62px;
}

@media only screen and (max-width: 400px) {
  .about_article {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 330px) {
  .about_article {
    font-size: 1.7rem;
  }
}
