.tabs {
  background-color: var(--table-background);
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
  width: auto;

  &__tab {
    background-color: var(--table-background);
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    color: var(--table-text);
    height: 100%;
    vertical-align: middle;
    width: 50%;
    font-weight: 200;
    padding-top: 0.5rem;
  }

  .selected {
    background-color: var(--table-field);
    font-weight: 400;
    padding-top: 0.5rem;
  }
}
