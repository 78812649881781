:root {
  --button-color: #00a775;
  --button-color-hover: #11b081;
  --button-danger-color: #da1e28;
  --button-danger-color-hover: #b81921;
}

.button {
  background-color: transparent;
  color: var(--table-text);
  transition-duration: 200ms;
  cursor: pointer;
  font-weight: 200;

  &:hover {
    background-color: var(--button-color-hover);
    color: var(--table-text);
  }

  &__large {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.5rem;
  }

  &__regular {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.3rem;
  }

  &__small {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 1rem;
  }

  &__primary {
    background-color: var(--button-color);
  }

  &__secondary {
    background-color: transparent;
    border: solid 1px var(--button-color);
  }

  &__ghost {
    background-color: transparent;
    &:hover {
      background-color: var(--koala-border);
    }
  }

  &__danger {
    &__primary {
      background-color: var(--button-danger-color);
    }

    &__secondary {
      background-color: transparent;
      border: solid 1px var(--button-danger-color);
    }

    &__ghost {
      background-color: transparent;
    }
  }

  &__settings {
    color: var(--koala-text);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.danger {
  &:hover {
    background-color: var(--button-danger-color-hover);
  }
}
