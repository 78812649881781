.baseHamburger {
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: unset;
  margin-right: 5px;
}

.baseHamburger .hamSpan {
  position: absolute;
  background-color: gray;
  border-radius: 500px;
  width: 100%;
  height: 20%;
  transition: background-color 100ms;
}

.baseHamburger .hamSpan::before {
  content: '';
  position: absolute;
  background-color: gray;
  border-radius: 500px;
  width: 100%;
  height: 100%;
  top: -200%;
  transform-origin: center left;
  transition: transform 200ms;
}

.baseHamburger .hamSpan::after {
  content: '';
  position: absolute;
  background-color: gray;
  border-radius: 500px;
  width: 100%;
  height: 100%;
  bottom: -200%;
  transform-origin: center left;
  transition: transform 200ms;
}

.baseHamburger.active .hamSpan {
  background-color: #80808000;
}

.baseHamburger.active .hamSpan::before {
  transform: scale(1.15) rotate(45deg);
}

.baseHamburger.active .hamSpan::after {
  transform: scale(1.15) rotate(-45deg);
}
