:root {
  --koala-green: #00aa6e;
  --koala-gray: #3a3a3a;
  --koala-midgray: rgb(77, 77, 77);
  --koala-lightgray: #999;
  --koala-background: #f7f7f7;
  --koala-section-background: #f7f7f7;
  --koala-section-background-dark: #f7f7f7;
  --koala-pill: rgb(77, 77, 77);
  --koala-light-text: white;
  --koala-text: rgb(77, 77, 77);
  --koala-border: lightgray;
}

[data-theme='dark'] {
  --koala-background: #292f33;
  --koala-section-background: #1f2429;
  --koala-section-background-dark: rgb(77, 77, 77);
  --koala-text: white;
  --koala-pill: #1f2429;
  --koala-border: #677580;
}

html {
  min-height: 100vh;
  background-color: var(--koala-background);
  display: flex;
}

body {
  flex: 1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
}

a {
  color: var(--koala-text)
}

::selection {
  color: var(--koala-background);
  background: var(--koala-text); /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: var(--koala-background);
  background: var(--koala-text); /* Gecko Browsers */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.add-koala-text {
  color: var(--koala-text);
  display: flex;
  margin: 0 auto;
  top: 50%;
  text-align: center;
  font-size: 40pt;
  animation: fade-in 2s linear 1s;
  animation-fill-mode: backwards;
}

.add-koala-text-small {
  color: var(--koala-text);
  display: flex;
  margin: 0 auto;
  top: 50%;
  text-align: center;
  font-size: 12pt;
  animation: fade-in 2s linear 1s;
  animation-fill-mode: backwards;
}

.loading {
  color: var(--koala-text);
  margin: auto;
}

#big-koala-icon {
  opacity: 0.6;
  display: flex;
  margin: 5em auto 5em auto;
}

.baseNav {
  display: flex;
  width: 50px;
  background-color: var(--koala-midgray);
  overflow: hidden;
  transition: width 250ms;
  flex-direction: column;
  position: fixed;
  left: 0;
  height: 100%;
}

.baseNav.active {
  width: 250px;
}

@media (max-height: 600px) {
  .baseNav {
    display: flex;
    width: 70px;
    background-color: var(--koala-midgray);
    overflow: hidden;
    overflow-y: auto;
    transition: width 250ms;
    flex-direction: column;
    position: fixed;
    left: 0;
    height: 100%;
  }

  .baseNav.active {
    width: 265px;
  }
}

.navButton,
.baseNav div.page,
.baseNav div.dark,
.baseNav div.dark-active,
.baseNav div.active {
  text-decoration: none;
}

.baseNav .navButton,
.baseNav div.page,
.baseNav div.dark,
.baseNav div.dark-active,
.baseNav div.active {
  width: 250px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--koala-lightgray);
}

.baseNav .navButton:hover,
.baseNav div.page:hover,
.baseNav div.dark:hover,
.baseNav div.dark-active,
.baseNav div.active {
  color: white;
}

.baseNav .navButton .dark,
.baseNav div.dark,
.baseNav div.dark-active {
  background-color: var(--koala-gray);
}

.baseNav .navButton:nth-child(1),
.baseNav div.page:nth-child(1),
.baseNav div.dark:nth-child(1),
.baseNav div.dark-active:nth-child(1),
.baseNav div.active:nth-child(1) {
  margin-top: 5px;
  margin-bottom: 5px;
}

.baseNav svg {
  width: 50px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* padding: calc(50px - 100% / 2); */
}

.baseNav .baseIcon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem 1rem 0.8rem;
}

.baseNav .baseIconHamburger {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.baseNav p {
  margin: 0;
  margin-bottom: 3px;
}

.baseNav input {
  color: white;
  background-color: var(--koala-lightgray);
  border: none;
  border-radius: 2%;
  -webkit-appearance: none;
  outline: none !important;
  height: 60%;
  font-size: 1.3rem;
  width: calc(100% - 70px);
}

.headerLeftSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
}

.withSidebar {
  margin-left: 50px;
}

#headerLogo {
  height: 45px;
  margin-left: 10px;
}

#headerLogoText {
  height: 35px;
  margin-left: 20px;
}

.baseHeader {
  height: 60px;
  width: 100%;
  background: var(--koala-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  color: white !important;
}

.baseHeader .profile-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-left: 20px;
  border: 2px solid var(--koala-midgray);
  border-radius: 50%;
  background-color: white;
  color: var(--koala-midgray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.baseHeader .headerLogo {
  cursor: pointer;
}

.baseHeader .headerRightSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.baseHeader .vertical-container {
  height: 100%;
  color: white;
}

.baseHeader .serverGuilds {
  font-size: 1.2rem;
  margin: 0 1rem 0 1rem;
  font-weight: 500;
}

.baseHeader .selectGuilds {
  margin-right: 10px;
  padding: 5px;
  font-size: 1.2rem;
  border: none;
  background-color: var(--koala-green);
  width: 200px;
  text-overflow: ellipsis;
  color: white;
  cursor: pointer;
  outline: none;
}

.baseHeader .tableOptions {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.baseHeader .tableOptions > * {
  margin: 0 1rem 0 1rem;
}

.baseHeader h4 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

.baseHeader p {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.baseHeader a {
  font-weight: 500;
  margin: 1rem;
}

main {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.baseFooter {
  height: 50px;
  background-color: var(--koala-section-background);
  border-top: 1px solid var(--koala-lightgray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.baseFooter p {
  color: var(--koala-text);
  margin: 0;
}

.baseSection {
  margin: 50px;
  color: var(--koala-text);
  margin-bottom: 30px;
  align-self: center;
}

section h3 {
  font-weight: 400;
  font-size: 1.3rem;
}

section p {
  font-weight: 100;
}

section * {
  margin: 0;
}

.content section {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 800px) {
  .content section {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .content section {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Switch css */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 25px;
  margin-left: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.darkModeSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.darkModeSlider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .darkModeSlider {
  background-color: #2196f3;
}

input:focus + .darkModeSlider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .darkModeSlider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.darkModeSlider.round {
  border-radius: 34px;
}

.darkModeSlider.round:before {
  border-radius: 50%;
}

#cookieBanner {
  background-color: var(--koala-section-background);
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  border-top: 2px solid gray;
  transform: translateY(100%);
  transition: transform 250ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

#cookieBanner.open {
  transform: translateY(0);
}

#cookieContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: 80%;
  height: 100%;
  align-items: center;
}

#cookieContainer .button {
  border-radius: 5%;
  background-color: lightgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 200ms;
  padding: 15px;
  border: 1px solid lightgray;
}

#cookieContainer .button:hover {
  background-color: #7bcc7b;
}

.center-screen {
  display: flex;
  width: 50%;
  align-items: center;
  min-height: 87vh;
  margin: auto;
  color: var(--koala-text);
}

.icon {
  padding-right: 0.5rem;
}
